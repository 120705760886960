<template>
  <div class="container">
    <h3>Hoş Geldiniz</h3>
    <p>
      {{ this.$store.state.auth.user.given_name }}
      {{ this.$store.state.auth.user.family_name }}
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    this.$store.commit('theme/addToBreadcrumps', [{
      title: "Anasayfa",
      path: "/"
    }]);
  },
  
};
</script>